jQuery(document).ready(function($) {
    $('.slider').each(function() {
        initializeSliderBlock($(this));
    });
});   

var initializeSliderBlock = function($block) {
	let container = $block.find('.slider-container');
	let responsive;
    if(container.data('visible-slides') == 1) {
		responsive = [
			{
				breakpoint: 767.98,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		  ]
    } else {
      responsive = [
        {
			breakpoint: 991.98,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				dots: true
			}
        },
        {
			breakpoint: 767.98,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
        }
      ]
    }
    
    container.slick({
        slidesToShow: container.data('visible-slides'),
        slidesToScroll: container.data('visible-slides'),
        dots: true,
        prevArrow: '<button type="button" class="slider-nav-prev"><i class="fas fa-fw fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slider-nav-next"><i class="fas fa-fw fa-chevron-right"></i></button>',
        dotsClass: 'slider-nav-dots',
        appendArrows: $block.find('.slider-navigation'),
        appendDots: $block.find('.slider-navigation'),
        responsive: responsive
    });
}

// Initialize dynamic block preview (editor).
if(window.acf) {
    window.acf.addAction( 'render_block_preview/type=emt-block-hero_slider', initializeSliderBlock );
    window.acf.addAction( 'render_block_preview/type=emt-block-slider', initializeSliderBlock );
    window.acf.addAction( 'render_block_preview/type=emt-block-post-slider', initializeSliderBlock );
    window.acf.addAction( 'render_block_preview/type=emt-block-relationship-slider', initializeSliderBlock );
}

if(jQuery('.youtube-player').length) {
    (function() { var v = document.getElementsByClassName("youtube-player"); for (var n = 0; n < v.length; n++) { v[n].onclick = function () { var iframe = document.createElement("iframe"); iframe.setAttribute("src", "//www.youtube.com/embed/" + this.dataset.id + "?autoplay=1&autohide=2&border=0&wmode=opaque&enablejsapi=1&rel="+ this.dataset.related +"&controls="+this.dataset.control+"&showinfo=" + this.dataset.info); iframe.setAttribute("frameborder", "0"); iframe.setAttribute("id", "youtube-iframe"); iframe.setAttribute("style", "width: 100%; height: 100%; position: absolute; top: 0; left: 0;"); if (this.dataset.fullscreen == 1){ iframe.setAttribute("allowfullscreen", ""); } while (this.firstChild) { this.removeChild(this.firstChild); } this.appendChild(iframe); }; } })();
}