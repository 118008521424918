// // You have to used window load to make sure that the block js doesnt run until after everithing is loaded on the gutenberg side.
let $ = jQuery;
// $(window).bind('load', () => {

// });   

jQuery(function() {
    "use strict"

    $('.year-highlight-images').each(function() {
        initializeYearHighlightImagesSliderBlock($(this));
    });
    
});

let initializeYearHighlightImagesSliderBlock = ($block) => {
    let container = $block.find('.year-highlight-images-slider');
    container.slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        customPaging: (slider, i) => {
            let title = $(slider.$slides[i]).data('year');
            return '<a class="pager__item"> ' + title + ' </a>';
        }
    });

    if($('.block-editor__typewriter').length == 0) {
        container.slickLightbox({
            src: 'src',
            itemSelector: '.images > .image > img',
            navigateByKeyboard: false,
            lazy: true
        })
    }
}

// Initialize dynamic block preview (editor).
if(window.acf) {
    window.acf.addAction( 'render_block_preview/type=e8-block-year-highlight-images', initializeYearHighlightImagesSliderBlock );
}